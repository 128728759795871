import React from "react";
import * as styles from "./footer.module.scss";

export function VdrFooter() {
  return (
    <div className={styles.container}>
      <footer className={styles.footer}>
        <div>
          <a href="mailto:hello@stackdx.com" className={styles.smallText}>
            hello@stackdx.com
          </a>
          <p className={styles.smallText}>
            639 5 Ave SW #1100, Calgary, AB T2P 0M9
          </p>
          <p className={styles.smallText}>© 2024 Stack Technologies Ltd.</p>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/company/stack-technologies-ltd"
            aria-label="Link to StackDX LinkedIn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              viewBox="0,0,256,256"
              className={styles.icon}
            >
              <title>LinkedIn</title>
              <g fill="currentColor">
                <g transform="scale(5.12,5.12)">
                  <path d="M41,4h-32c-2.76,0 -5,2.24 -5,5v32c0,2.76 2.24,5 5,5h32c2.76,0 5,-2.24 5,-5v-32c0,-2.76 -2.24,-5 -5,-5zM17,20v19h-6v-19zM11,14.47c0,-1.4 1.2,-2.47 3,-2.47c1.8,0 2.93,1.07 3,2.47c0,1.4 -1.12,2.53 -3,2.53c-1.8,0 -3,-1.13 -3,-2.53zM39,39h-6c0,0 0,-9.26 0,-10c0,-2 -1,-4 -3.5,-4.04h-0.08c-2.42,0 -3.42,2.06 -3.42,4.04c0,0.91 0,10 0,10h-6v-19h6v2.56c0,0 1.93,-2.56 5.81,-2.56c3.97,0 7.19,2.73 7.19,8.26z"></path>
                </g>
              </g>
            </svg>
          </a>
        </div>
      </footer>
    </div>
  );
}
