import React from "react";
import email_icon from "../../images/email.svg";
import pn_white_icon from "../../images/pn_pure_white_icon.svg";
import stackdx_icon from "../../images/stackdx_pure_white_icon.svg";
import art from "../../images/vdr_art.svg";
import { CheckIcon } from "./CheckIcon";
import { VdrFooter } from "./VdrFooter";
import { VdrHeader } from "./VdrHeader";
import * as styles from "./vdr.module.scss";

const VdrMain = () => {
  return (
    <div className={styles.background}>
      <VdrHeader />

      <div className={styles.container}>
        <div className={styles.heroContainer}>
          <div className={styles.heroTextContainer}>
            <h1>Stack Virtual Data Rooms</h1>

            <p style={{ marginTop: "1.5rem" }}>
              Data rooms have come a long way from boxes of paper and a laptop.
              Stack VDRs let sellers quickly set up a data room and ensure the
              purchaser is ready to operate upon close.
            </p>

            <a href="mailto:hello@stackdx.com">
              <button style={{ marginTop: "2.5rem" }}>Get a demo</button>
            </a>
          </div>

          <div className={styles.artContainer}>
            <img src={art} alt="" className={styles.art} />
          </div>
        </div>

        <div className={styles.content1Container}>
          <h2>Built for Oil & Gas</h2>

          <p style={{ marginTop: "1.5rem" }}>
            A virtual data room (VDR) is a secure online space where you can
            store and share sensitive information with potential buyers. We took
            this concept one step further and designed Stack VDRs specifically
            for the oil and gas industry. Our solution integrates with your land
            systems, visualizes your data on a map, and provides an easy way to
            cross-reference this information with all parties and regulators.
          </p>
        </div>

        <div className={styles.statsContainer}>
          <div>
            <div className={`${styles.icon} ${styles.stackIcon}`}>
              <img src={stackdx_icon} alt="StackDX icon" />
            </div>
            <h3 className={styles.tagline}>Data Collection</h3>
            <p>
              Stack clients can gather all records related to a disposition and
              build a VDR in minutes. Configure the VDR to determine who can
              view it (internal/external) along with the functionality and data
              they can access.
            </p>
          </div>

          <div>
            <div className={`${styles.icon} ${styles.pnIcon}`}>
              <img src={pn_white_icon} alt="Petro Ninja icon" />
            </div>
            <h3 className={styles.tagline}>Mapping</h3>
            <p>
              View the disposition's minerals, contracts, surface, wells, pipes,
              and facilities on the map. Compare against your corporate layers
              to quickly confirm that all the necessary records are included.
            </p>
          </div>

          <div>
            <div className={`${styles.icon} ${styles.emailIcon}`}>
              <img src={email_icon} alt="Email icon" />
            </div>
            <h3 className={styles.tagline}>Hosting</h3>
            <p>
              Invite potential buyers into a marketing room or a due diligence
              room regardless of whether they already are Stack clients or not.
              Track all activity and notify all parties whenever new records are
              added.
            </p>
          </div>
        </div>

        <div className={styles.content2Container}>
          <div className={styles.forSellers}>
            <h2>For sellers</h2>

            <div className={styles.stepsContainer}>
              <div>
                <div className={styles.graph}>
                  <div className={styles.square} />
                  <div className={styles.line} />
                </div>
                <h3 className={styles.tagline}>Collect</h3>
                <p>
                  Automatically pull all land, well, pipe, and facility
                  documents through a land system query, map selection, or
                  StackDX criteria
                </p>
              </div>

              <div>
                <div className={styles.graph}>
                  <div className={styles.square} />
                  <div className={styles.line} />
                </div>
                <h3 className={styles.tagline}>Validate</h3>
                <p>
                  Search, visualize on the map, and run reports across all VDR
                  contents with built-in validation tools
                </p>
              </div>

              <div>
                <div className={styles.graph}>
                  <div className={styles.square} />
                  <div className={styles.line} />
                </div>
                <h3 className={styles.tagline}>Invite</h3>
                <p>
                  Invite potential purchasers to view marketing materials or
                  conduct due diligence with a full audit trail
                </p>
              </div>

              <div>
                <div className={styles.graph}>
                  <div className={styles.square} />
                  <div className={styles.line} />
                </div>
                <h3 className={styles.tagline}>Transfer</h3>
                <p>
                  Upon close, Stack manages the document transfer and provides
                  audit trail along with transfer records
                </p>
              </div>
            </div>
          </div>

          <div className={styles.forPurchasers}>
            <div>
              <h2>For purchasers</h2>

              <ul>
                <li>
                  <CheckIcon className={styles.checkIcon} /> Easy data migration
                  for Stack and non-Stack users
                </li>
                <li>
                  <CheckIcon className={styles.checkIcon} /> Re-map land files
                </li>
                <li>
                  <CheckIcon className={styles.checkIcon} /> Get reports on data
                  health
                </li>
                <li>
                  <CheckIcon className={styles.checkIcon} /> Identify missing
                  data while the seller is still engaged
                </li>
                <li>
                  <CheckIcon className={styles.checkIcon} /> Get access to the
                  data immediately upon close
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={styles.closingContainer}>
          <h2>Get started</h2>

          <p style={{ marginTop: "1.5rem" }}>
            Let Stack manage the data so you can focus on the deal.
          </p>

          <a href="mailto:hello@stackdx.com">
            <button style={{ marginTop: "2.5rem" }}>Contact us</button>
          </a>
        </div>
      </div>

      <VdrFooter />
    </div>
  );
};

export default VdrMain;
