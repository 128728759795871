import React from "react";
import logo from "../../images/stackdx_text_white_logo.svg";
import * as styles from "./header.module.scss";

export function VdrHeader() {
  return (
    <header className={styles.header}>
      <img src={logo} alt="StackDX logo" width={160} />
    </header>
  );
}
