// extracted by mini-css-extract-plugin
export var art = "vdr-module--art--5cb65";
export var artContainer = "vdr-module--artContainer--07622";
export var background = "vdr-module--background--f1d86";
export var checkIcon = "vdr-module--checkIcon--a5e72";
export var closingContainer = "vdr-module--closingContainer--17b82";
export var container = "vdr-module--container--6fed4";
export var content1Container = "vdr-module--content1Container--fad2c";
export var content2Container = "vdr-module--content2Container--5175d";
export var emailIcon = "vdr-module--emailIcon--223ce";
export var forPurchasers = "vdr-module--forPurchasers--1cd1d";
export var forSellers = "vdr-module--forSellers--953c9";
export var graph = "vdr-module--graph--26081";
export var heroContainer = "vdr-module--heroContainer--3913c";
export var heroTextContainer = "vdr-module--heroTextContainer--8c8dc";
export var icon = "vdr-module--icon--119ce";
export var line = "vdr-module--line--a44bb";
export var pnIcon = "vdr-module--pnIcon--10b08";
export var square = "vdr-module--square--32fa0";
export var stackIcon = "vdr-module--stackIcon--1f63f";
export var statsContainer = "vdr-module--statsContainer--cf0fc";
export var stepsContainer = "vdr-module--stepsContainer--91095";
export var tagline = "vdr-module--tagline--54962";