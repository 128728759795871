import * as React from "react";
import { Helmet } from "react-helmet";
import VdrMain from "../components/Vdr";

export default function Vdr() {
  return (
    <>
      <Helmet defer={false}>
        <title>Stack VDR</title>
        <meta
          name="description"
          content="Stack Virtual Data Rooms revolutionize M&A in Oil & Gas industry."
        />
        <html lang="en" />
      </Helmet>

      <VdrMain />
    </>
  );
}
